<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="1000px"
  >
    <v-form
      ref="form"
      lazy-validation
      :disabled="isViewMode"
    >
      <v-card>
        <v-card-title>
          <div class="text-h3 header-text">
            {{ $t("ticket_no") }}{{ ticket.reference_code }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="ticket.customer"
                  dense
                  disabled
                  outlined
                  :label="$t('oem_customer')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="ticket.user_type"
                  dense
                  disabled
                  outlined
                  :label="$t('customer_type')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="ticket.category"
                  disabled
                  dense
                  outlined
                  :label="$t('category')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="ticket.chassis_no"
                  disabled
                  dense
                  outlined
                  :label="$t('chassis_no')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="ticket.phone_number"
                  disabled
                  dense
                  outlined
                  :label="$t('phone_number')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="ticket.created_at"
                  dense
                  outlined
                  disabled
                  :label="$t('created_at')"
                />
              </v-col>

              <template v-if="ticket.is_appointment_created">
                <v-col
                  cols="12"
                  sm="12"
                  class="pt-0"
                >
                  <v-divider class="seprator-custom" />
                  <div class="text-h3 header-text mt-5 sub-text mb-0">
                    Appointment Ticket
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="ticket.appointment_date"
                    dense
                    outlined
                    disabled
                    :label="$t('appointment_date')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-model="ticket.appointment_timing"
                    dense
                    disabled
                    outlined
                    :label="$t('appointment_timing')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                >
                  <!-- v-model="ticket.appointment_status" -->
                  <v-select
                    v-model="appointmentStatus"
                    :disabled="ticket.appointment_status == 'completed'"
                    dense
                    outlined
                    :items="appointmentStatusList"
                    item-value="id"
                    item-text="name"
                    :label="$t('appointment_status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </template>

              <v-col
                cols="12"
                sm="12"
                class="pt-0 pb-5"
              >
                <v-divider class="seprator-custom" />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-textarea
                  v-model="ticket.description"
                  disabled
                  dense
                  outlined
                  :label="$t('details')"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-textarea
                  v-model="ticket.response"
                  dense
                  outlined
                  :label="$t('message_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <template v-if="ticket.ticket_attachments.length > 0">
                <v-col cols="12">
                  <v-row>
                    <v-col
                      v-for="(attachment, key) in ticket.ticket_attachments"
                      :key="key"
                      class="d-flex child-flex"
                      cols="2"
                    >
                      <v-img
                        :src="`${attachment.media}`"
                        :lazy-src="`https://picsum.photos/10/6?image=${
                          key * 5 + 10
                        }`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            />
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="close-btn"
            @click="closeModal()"
          >
            Close
          </v-btn>
          <v-btn
            v-if="!isViewMode"
            color="primary"
            class="save-btn"
            text
            :disabled="isViewMode"
            :loading="isLoadingSave"
            @click="updateItem(ticket)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    isViewMode: Boolean,
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      appointmentStatusList: ["approved", "completed"],
      appointmentStatus: "",
    };
  },
  mounted() {
    this.appointmentStatus = this.ticket.appointment_status;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async updateItem(ticket) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        this.ticket.appointment_status = this.appointmentStatus;
        await this.$store
          .dispatch("supportTickets/update", ticket)
          .then(async () => {
            await this.$store.dispatch("supportTickets/list");
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeModal();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
<style>
.theme--light.v-label {
  color: black !important;
  font-size: 16px !important;
}
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 16px !important;
}
.header-text {
  color: #20a390;
  margin-bottom: 1rem;
  font-weight: bold !important;
  text-align: left !important;
}
.close-btn {
  background: #909090;
  color: #fff !important;
}
.save-btn span,
.close-btn span {
  color: #fff !important;
}
.save-btn {
  background: #20a390;
  color: #fff !important;
}
.save-btn:hover {
  background: #20a390d1 !important;
}
.close-btn:hover {
  background: #363636 !important;
}
.seprator-custom {
  border-color: #e0d3d3 !important;
}
.v-application .text-h3.sub-text {
  color: #363636;
  font-size: 16px !important;
}
textarea {
  resize: none;
}
</style>
